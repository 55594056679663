.footerImage {
    height: 40px;
    padding-right: 15px;
}

.footerImage:hover{
    opacity: 0.7;
}

.footerContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 15px;
    text-align: left;
    z-index: 3; /* Above loading overlay */
}
