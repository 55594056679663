body {
  text-align: center;
  margin: 20px;
  font-family: Roboto;
  font-weight: bold;
}

.options {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3; /* Above loading overlay */
}

canvas {
  z-index: 1; /* Below everything */
}